//external components
import { useEffect, useState } from 'react';
import { AppShell, Navbar, Header } from '@mantine/core';
import Cookies from 'js-cookie';

//Internal components
import Login from './sitewide/Login';
import HeaderContent from './sitewide/HeaderContent';
import NavbarContent from './sitewide/NavbarContent';
import AppContent from './sitewide/AppContent';


//hooks/context
import { useAuth } from './context/AuthContext';
import { LoadScript } from '@react-google-maps/api';

const libraries=["places"]


function App() {
  //is the user authenticated?
  const {isAuthenticated, token} = useAuth()

  //Get the value from the search box.
  const [searchText, setSearchText] = useState('')

  //This sets the content that is shown in the main app tray
  const [pageInView, setPageInView] = useState('sales')
  const [navbarVisible, setNavbarVisible] = useState(true)
  const [appShellPadding, setAppShellPadding] = useState(0)

  function navBarContent(){
    if(navbarVisible){
      return <Navbar width={{ base: 300 }} height={"100%"} p="xs">{<NavbarContent pageInView={pageInView} setPageInView={setPageInView} searchText={searchText} setSearchText={setSearchText} />}</Navbar>
      
    }
    else{
      return <></>;
    }
  }
  
  
  

  const apiKey = 'AIzaSyCkBkHJc6yfTCrvyPS9EN3h2xNongM2a54';

  useEffect(()=>{
    Cookies.set('pageInView', pageInView);
    if(pageInView == "sales"){
      setAppShellPadding(10)
    }else{
      setAppShellPadding(0)
    }
  }, [pageInView])


  return (
    <>
      {isAuthenticated &&
      <LoadScript libraries={libraries}  googleMapsApiKey={apiKey}>
              <AppShell
              padding={appShellPadding}
              navbar={navBarContent()}
              header={<Header height={65} pt={10}  bg="white">{<HeaderContent  navbarVisible={navbarVisible} setNavbarVisible={setNavbarVisible}/>}</Header>}
              
              styles={(theme) => ({
                main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
              })}
            >
              {<AppContent pageInView={pageInView} setPageInView={setPageInView} searchText={searchText}/>}
            </AppShell>
            </LoadScript>
      }
      {!isAuthenticated && <Login />
      }
    </>
  );
}

export default App;
