import axios from 'axios';
import Cookies from 'js-cookie';

export const getCoveragePolygons = async () => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/coverage/polygons/get', {
        token
    });
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};