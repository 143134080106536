import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, LoadScript, StandaloneSearchBox, Autocomplete, Polygon} from '@react-google-maps/api';


import { TextInput } from '@mantine/core';
import { IconBroadcast } from '@tabler/icons-react';
import towerIcon from "../../icons/building-broadcast-tower.svg"




function MapWithSearchBox({
    setMapMarker,
    mapMarker,
    lat,
    lng,
    setAddressOne,
    setAddressTwo,
    setCity,
    setState,
    setZip,
    height="600px",
    towerLocations,
    customerLocations,
    coverages,
    rightClickMarker,
    leftClickMapMarker,
    rightClickTower,
    mapOverlayItems,
    leftClickTower,
    enableEditPolygons,
    coveragePolygons,
    setCoveragePolygons,
    newPolygon,
    setNewPolygon,
    rightClickSavedPolygon
    
    }){


  const [mapCenter, setMapCenter] = useState({lat: lat, lng: lng});
  const [zoom, setZoom] = useState(10);
  const [searchBox, setSearchBox] = useState();
  const [mapBounds, setMapBounds] = useState();

  const mapContainerStyle = {
    width: '100%',
    height: height,
  
  };
  const searchBounds = {
    north: lat+=0.1,
    south: lat-=0.1,
    west: lng +=0.1,
    east: lng -= 0.1
  }
  


function onPlacesChanged(){
    if(searchBox){
        let place = searchBox.getPlaces()
        let lat = place[0].geometry.location.lat()
        let lng = place[0].geometry.location.lng()
        setMapCenter({lat: lat, lng: lng})
        setMapMarker({lat: lat, lng: lng})
        setZoom(16)
        if(place[0].address_components){ 
          let street_number = place[0].address_components.find(component => component.types.includes('street_number'))
          let route = place[0].address_components.find(component => component.types.includes('route'))
          let city = place[0].address_components.find(component => component.types.includes('locality'))
          let state = place[0].address_components.find(component => component.types.includes('administrative_area_level_1'))
          let zip = place[0].address_components.find(component => component.types.includes('postal_code'))
          setAddressOne(street_number.long_name + " " + route.long_name)
          setCity(city.long_name)
          setState(state.long_name)
          setZip(zip.long_name)
        }   
    }
}
function userDblClick(e){
  console.log(newPolygon);
  if(enableEditPolygons){
    console.log('test');
    var lat = e.latLng.lat();
    var lng = e.latLng.lng();
    let point = {lat: e.latLng.lat(), lng: e.latLng.lng()};
    let polygon = [{lat: lat-0.01, lng: lng-0.01}, {lat: lat-0.01, lng: lng+0.01}, {lat: lat+0.01, lng: lng + 0.01},{lat: lat+0.01, lng: lng-0.01},];
    setNewPolygon(polygon);
  }
  
}
function technologyToVisual(type){
  var color = "black"
  switch(type){
    case 1:
      color = "blue"
      break
    case 2:
      color = "green"
      break
    case 3:
      color = "orange"
      break
    case 4:
      color = "red"
      break
  }
  return color

}

function userClickOnMap(e){
  let clickPoint = {lat: e.latLng.lat(), lng: e.latLng.lng()}
  setMapMarker(clickPoint);
}

function handleEditingPolyGons(e){
  if(!enableEditPolygons){
    return false;
  }
  console.log(e);
  let point = {lat: e.latLng.lat(), lng: e.latLng.lng()};
  console.log(point);
  let pointHolder = [];
  if("edge" in e){
    for(var i = 0; i < newPolygon.length; i++){
      pointHolder.push(newPolygon[i]);
      if(e.edge == i){
        pointHolder.push(point);
      }
    }
  }
  if("vertex" in e){
    for(var i = 0; i < newPolygon.length; i++){
      if(e.vertex == i){
        pointHolder.push(point);
      }else{
        pointHolder.push(newPolygon[i]);
      }
    }
  }
  setNewPolygon(pointHolder);
}
function setFillColor(polygonType){
  if(polygonType == 0){
    return "red"
  }else{
    return "blue"
  }
}


  
  if(lat && lng) {return (
        <GoogleMap 
        mapContainerStyle={mapContainerStyle} 
        center={mapCenter} 
        zoom={zoom} 
        mapTypeId={window.google.maps.MapTypeId.HYBRID}
        id="searchbox-example"
        onClick={(e) => {userClickOnMap(e)}}
        onDblClick={(e) =>{userDblClick(e)}}
        >
        
        <StandaloneSearchBox
        onLoad={(searchBox) => {setSearchBox(searchBox)}}
        onPlacesChanged={() => {onPlacesChanged()}}
        bounds={searchBounds}
        >
        <input
            type="text"
            placeholder="Search for an address"
            style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `400px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginLeft: "-120px"
            }}
        />
        </StandaloneSearchBox>
        {mapMarker && <Marker position={mapMarker} onClick={(e)=>{leftClickMapMarker(e)}} onRightClick={(e)=>{rightClickMarker(e)}}/>}
        {
           newPolygon && newPolygon.length > 3 && <Polygon visible={enableEditPolygons} onMouseUp={(e)=>{handleEditingPolyGons(e)}} key={1000} options={{fillColor: "#00ffff", fillOpacity: 0.1, clickable: false, editable: true}} paths={newPolygon.map((path)=>{return ({lat: parseFloat(path.lat), lng: parseFloat(path.lng)})})} />
        }
        {towerLocations && towerLocations.map((tower) => {
          return <Marker position={{lat: parseFloat(tower[1]), lng: parseFloat(tower[2])}} key={tower[0]} onClick={(e) =>{leftClickTower(tower)}}  onRightClick={(e)=>{rightClickTower(tower)}}  icon={ towerIcon } />
        })}
        {
        coveragePolygons && coveragePolygons.map((polygon)=>{
	
          return <Polygon key={polygon.id} onRightClick={()=>{rightClickSavedPolygon(polygon)}} options={{ visible: true, fillColor: setFillColor(polygon.type), fillOpacity: 0.1, clickable: enableEditPolygons, editable: false}} paths={polygon.paths} />
        })
        }
        {coverages && coverages.map((coverage)=>{
          if(coverage.ap_paths){
            var options = {
              fillColor: technologyToVisual(coverage.technology),
              fillOpacity: 0.1,
              strokeOpacity: 0.25,
              strokeWeight: 3,
              clickable: false

            }
            return <Polygon options={options} key={coverage.coverage_id} paths={[coverage.ap_paths.map((path)=>{return ({lat: parseFloat(path.lat), lng: parseFloat(path.lng)})})]}/>
          }
          
        })}
        
        {mapOverlayItems}
        
        
        </GoogleMap>

  );}
}

export default MapWithSearchBox;
