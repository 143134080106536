import { useEffect, useState } from "react"
import { Button, Group, Modal, Stack, TextInput, Textarea, Dialog, Title, Input, MultiSelect, Select } from "@mantine/core"

import MapWithSearchBox from "../components/maps/MapWIthSearchBox"
import TowerDetail from "../components/towers/TowerDetail"


import { upsertTower } from "../hooks/locations/useUpsertTower"
import { getTowers } from "../hooks/locations/useGetTowers"
import { deleteTower } from "../hooks/locations/useDeleteTower"
import { addCoveragePolygon } from "../hooks/coverage/useAddCoveragePolygon"
import { getCoveragePolygons } from "../hooks/coverage/useGetCoveragePolygons"
import { deleteCoveragePolygon } from "../hooks/coverage/useDeleteCoveragePolygon"
import { checkPolygonCoverage } from "../hooks/coverage/useCheckPolygonCoverage"

export default function Map(){

    const [mapMarker, setMapMarker] = useState()
    const [addressOne, setAddressOne] = useState()
    const [addressTwo, setAddressTwo] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [zip, setZip] = useState()
    


    //tower parameters
    const [towerId, setTowerId] = useState()
    const [towerName, setTowerName] = useState()
    const [towerHeight, setTowerHeight] = useState()
    const [towerLat, setTowerLat] = useState()
    const [towerLng, setTowerLng] = useState()
    const [towerNotes, setTowerNotes] = useState("")
    const [saveTowerSubmitButtonIsDisabled, setSaveTowerSubmitButtonDisabled] = useState(false)
    const [saveTowerErrorMessage, setSaveTowerErrorMessage] = useState()
    

    //state related to the map page
    const [viewEditTower, setViewEditTower] = useState(false)

    //state related to the map objects
    const [towerLocations, setTowerLocations] = useState([])
    const [towerCoverages, setTowerCoverages] = useState([])
    const [coveragePolygons, setCoveragePolygons] = useState([]);
    const [reloadMapObjects, setReloadMapObjects] = useState(false)

    //tower detail related state.
    const [towerDetailID, setTowerDetailID] = useState();
    const [towerDetailModalOpened, setTowerDetailModalOpened] = useState();

    //coverage polygon related state
    const [polygonPoints, setPolygonPoints] = useState([]);
    const [enableEditPolygons, setEnableEditPolygons] = useState(false);
    const [newPolygon, setNewPolygon] = useState([]);
    const [savePolygonModalOpened, setSavePolygonModalOpened] = useState(false);
    const [savePolygonName, setSavePolygonName] = useState();
    const [savePolygonType, setSavePolygonType] = useState(0);


    useEffect(()=>{
        getTowers().then((res)=>{
            setTowerLocations(res.towers);
            setTowerCoverages(res.coverages);
        });
        getCoveragePolygons()
        .then((res)=>{
            setCoveragePolygons(res.polygons);
            console.log(res);
        });
        
    }, [reloadMapObjects])

    

    function clearOutTowerForm(){
        setReloadMapObjects(!reloadMapObjects)
        setTowerId()
        setTowerHeight()
        setTowerLat()
        setTowerLng()
        setTowerNotes("")
        setTowerName()
        setViewEditTower(false)
        setSaveTowerSubmitButtonDisabled(false)
    }
    function rightClickMarker(e){
        clearOutTowerForm()
        setTowerLat(e.latLng.lat())
        setTowerLng(e.latLng.lng())
        setViewEditTower(!viewEditTower)
    }
    function leftClickMapMarker(e){
        if(enableEditPolygons){
            let point = {lat: e.latLng.lat(), lng: e.latLng.lng()};
            checkPolygonCoverage(point).then((res)=>{
                console.log(res);
                if(res.coverage == true && res.type == "Wireless"){
                    alert("Wireless Coverage");
                }
                if(res.coverage == true && res.type == "Fiber"){
                    alert("Fiber Coverage");
                }
                if(res.coverage == false){
                    alert("No Coverage");
                }
            });
        }
        
    }
    function rightClickTower(tower){
        setTowerId(tower[0])
        setTowerLat(tower[1])
        setTowerLng(tower[2])
        setTowerName(tower[3])
        setTowerHeight(tower[4])
        setTowerNotes(tower[12])
        setViewEditTower(true)
    }
    function deleteTowerConfirm(){
        const userConfirmedDeleteTower = window.confirm("Are you sure? This action is not reversable.")
        if(userConfirmedDeleteTower){
            setSaveTowerSubmitButtonDisabled(true)
            deleteTower(towerId).then((res)=>{
                clearOutTowerForm()
            })
                
        }
        
    }

    function handleUpsertTower(){
        setSaveTowerSubmitButtonDisabled(true)
        upsertTower(towerName, towerHeight, towerLat, towerLng, towerNotes,towerId)
        .then((res) => {
            if(res.status != "success"){
                setSaveTowerErrorMessage(res.message)
                setSaveTowerSubmitButtonDisabled(false)
                return
            }else{
                clearOutTowerForm();

            }
        })
    }
    function handleSavePolygon(){
        if(savePolygonName && savePolygonName != ""){
            addCoveragePolygon(newPolygon, savePolygonName, savePolygonType)
            .then((res) =>{
                if(res.status != "success"){
                    alert("Failed to save polygon");
                }else{
                    setSavePolygonModalOpened(false);
                    setSavePolygonName("");
                    setSavePolygonType(0);
                    setNewPolygon([]);
                    setReloadMapObjects(!reloadMapObjects);
                }
            });
        }else{
            alert("You must enter a name for this polygon");
        }
    }
    function leftClickTower(tower){
        setTowerDetailID(tower[0]);
        setTowerDetailModalOpened(true);
    }

    function returnMapOverlayItems(){
        return (
            <>
                <Dialog opened={true} withCloseButton={false} size="xs"  position={{bottom: 20, left: 20}}>
                    <Button onClick={()=>{setEnableEditPolygons(!enableEditPolygons)}}>Coverage</Button>
                    {enableEditPolygons && <Button color="red" onClick={()=>{setNewPolygon([])}}>Clear</Button>}
                    {enableEditPolygons && <Button onClick={()=>{if(newPolygon.length > 0){setSavePolygonModalOpened(true);}else{alert("You must double click to add a new polygon")}}} color="green"> Save</Button>}
                </Dialog>
            </>
        )
    }

    function handleCloseTowerDetail(){
        setTowerDetailModalOpened(false); 
        setReloadMapObjects(!reloadMapObjects);
    }
    function rightClickSavedPolygon(info){
        if(window.confirm("Are you sure you want to delete this polygon?")){
            deleteCoveragePolygon(info.id)
            .then((res)=>{
                if(res.status != "success"){
                    alert("Unable to delete polygon, please try again");
                }else{
                    setReloadMapObjects(!reloadMapObjects);
                }
            });
        }
    }
    return(
        <>

        <MapWithSearchBox 
                setMapMarker={setMapMarker}
                mapMarker={mapMarker}
                lat={33.09823522991901} lng={-111.70625043806123} 
                setAddressOne={setAddressOne} 
                setAddressTwo={setAddressTwo}  
                setCity={setCity}
                setState={setState}
                setZip={setZip}
                height={"100%"}
                rightClickMarker={rightClickMarker}
                leftClickMapMarker={leftClickMapMarker}
                towerLocations={towerLocations}
                coverages={towerCoverages}
                rightClickTower={rightClickTower}
                mapOverlayItems={returnMapOverlayItems()}
                leftClickTower={leftClickTower}
                enableEditPolygons={enableEditPolygons}
                newPolygon={newPolygon}
                setNewPolygon={setNewPolygon}
                coveragePolygons={coveragePolygons}
                setCoveragePolygons={setCoveragePolygons}
                rightClickSavedPolygon={rightClickSavedPolygon}
            />
            
            <Modal  opened={viewEditTower} title="Create/Edit Tower" onClose={() =>{setViewEditTower(false)}}> 
                <Stack>
                    <TextInput label="Tower Name" value={towerName} onChange={(e) => setTowerName(e.target.value)} />
                    <TextInput type={"number"}  label="Tower Height" value={towerHeight} onChange={(e) => setTowerHeight(e.target.value)} />
                    <Group grow>
                        <TextInput type={"number"} label="Tower Lat" value={towerLat} onChange={(e) => setTowerLat(e.target.value)} />
                        <TextInput type={"number"}  label="Tower Lng" value={towerLng} onChange={(e) => setTowerLng(e.target.value)} />
                    </Group>
                    <Textarea  minRows={3} label="Tower Notes" value={towerNotes} onChange={(e) => setTowerNotes(e.target.value)} />
                    <Group grow>
                        <Button disabled={saveTowerSubmitButtonIsDisabled} onClick={()=>{handleUpsertTower()}}> Save</Button>
                        {towerId && <Button disabled={saveTowerSubmitButtonIsDisabled} color="red" onClick={()=>{deleteTowerConfirm()}}> Delete</Button> }
                    </Group>
                </Stack>
            </Modal>


            <Modal fullScreen size={"xl"} opened={towerDetailModalOpened} onClose={()=>{handleCloseTowerDetail()}}>
                <TowerDetail tower_id={towerDetailID} />
            </Modal>
            <Modal title={"Save Polygon"} opened={savePolygonModalOpened} onClose={()=>{setSavePolygonModalOpened(false)}}>
                <Modal.Body>
                    <Input.Label>Polygon Name</Input.Label>
                    <Input value={savePolygonName} onChange={(e)=>{setSavePolygonName(e.target.value)}} placeholder="Required" />
                    <Select label={"Type"} value={savePolygonType} onChange={setSavePolygonType} placeholder="Coverage Type" data={[
                        {label: "Wireless", value: 0}, 
                        {label: "Fiber", value: 1}, 
                        ]}/>

                    <Button onClick={()=>{handleSavePolygon()}}>Save Polygon</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}
